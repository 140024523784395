:host {
}

@media only screen and (min-width: 1600px) {
  .servicesShared {
    display: block;
    max-width: 1600px;
    margin: auto;
  }
}

.servicesShared {

  display: flex;
  justify-content: space-between;
  width: 100%;
  flex: 1 1 100%;
  padding-top: 250px;

  &.lessMargin {

    padding-top: 150px;
  }

  .leftSection,
  .rightSide {
    display: flex;
    flex: 1 1 50%;
    flex-wrap: wrap;
    width: 50%;

    .smallPicture {
      display: none;
    }

  }


  .pic1 {
    background-image: url('//cdn.globalteams.ltd/assets/images/services/1.jpg');
  }

  .pic2 {
    background-image: url('//cdn.globalteams.ltd/assets/images/services/2.jpg');
    background-size: cover;
  }

  .pic3 {
    background-image: url('//cdn.globalteams.ltd/assets/images/services/3.jpg');
  }

  .pic4 {
    background-image: url('//cdn.globalteams.ltd/assets/images/services/4.jpg');
  }

  .pic5 {
    background-image: url('//cdn.globalteams.ltd/assets/images/services/5.jpg');
  }

  .pic6 {
    background-image: url('//cdn.globalteams.ltd/assets/images/services/6.jpg');
  }

  .pic7 {
    background-image: url('//cdn.globalteams.ltd/assets/images/whyus/12.jpg');
  }

  .pic13 {
    background-image: url('//cdn.globalteams.ltd/assets/images/whyus/13.jpg');
  }

  .rightSide {
    .picture {
      display: flex;
      flex: 1 1 100%;
      width: 100%;
      height: 580px;
      background-position: right center;
      background-repeat: no-repeat;

    }
  }

  .leftSection {
    padding: 0px 60px 0px 130px;
    box-sizing: border-box;

    .title {
      font-family: Comfortaa;
      font-style: normal;
      font-weight: normal;
      font-size: 100px;
      line-height: 100px;
      letter-spacing: 0.01em;
      text-transform: initial;
      color: #6297DB;
      // flex-wrap: wrap;
      // display: flex;

      span {
        color: #25446B;
      }
    }

    .content {
      font-family: Comfortaa;
      font-style: normal;
      font-weight: normal;
      font-size: 20px;
      line-height: 23px;
      color: #25446B;
    }


  }
}

@media only screen and (max-width: 1000px) {

  app-supporting-services {
    padding-top: 0px !important;

    .title {
      margin-top: 60px !important;
    }
  }

  app-rotating-technologies {
    display: block;
    margin: 0px 0px 60px !important;
    overflow: hidden;
    width: 100%;
    height: 100px !important;
  }

  .servicesShared {
    flex-wrap: wrap;
    padding-top: 150px;

    &.lessMargin {
      padding-top: 50px;
    }

    .leftSection,
    .rightSide {
      display: flex;
      flex: 1 1 100%;
      flex-wrap: wrap;
      width: 100%;

      .title {
        font-size: 40px;
        line-height: 50px;
      }

      .content {
        font-size: 16px;
        line-height: 18px;
      }

      .picture {
        display: none;
      }

    }

    .leftSection {
      padding: 0px 30px;

      .smallPicture {
        display: flex;
        flex: 1 1 100%;
        width: 100%;
        height: 280px;
        background-position: right center;
        background-repeat: no-repeat;
        display: block;
        margin: 20px 0px;
        background-size: cover;
      }
    }

    .letsTalkButton {
      width: 100%;
      margin-bottom: 50px;
      display: none;

      &.viewInMobile {
        display: block;
      }
    }
  }


}

.letsTalkButton {
  width: 200px;
  height: 60px;
  background: linear-gradient(291.99deg, #7398E3 0%, #18D6CF 100%);
  border-radius: 80px;
  font-family: Comfortaa;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 60px;
  text-align: center;
  text-transform: initial;
  color: #FFFFFF;
  margin-top: 50px;
  transition: background-color 1s;
  box-sizing: border-box;
  border: 2px solid #fff;
  outline: 0px;

  &:hover {
    background: none;
    cursor: pointer;
    background-color: white;
    color: #25446B;
    border: 2px solid #25446B;

  }


}


.position {
  .title {
    span {
      color: #6297DB;
    }
  }
}


ul {
  padding-left: 17px;

  li {
    font-family: Comfortaa;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 30px;
    margin-left: 30px;
    letter-spacing: 0.02em;
    color: #25446B;
    padding-left: 10px;

    span {
      font-weight: 600;
    }
  }
}


@media only screen and (max-width: 600px) {

  ul {

    li {
      font-size: 13px;
      line-height: 25px;
    }
  }

}
