select,
input {
  outline: 0px
}

.fileUploadInput,
input {
  font-family: Comfortaa;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.02em;
  border: 0px;
  border-bottom: 1px solid #0A2452;
  height: 36px;
  width: 100%;
  padding: 0px;
  margin: 0px;

  &::placeholder {
    font-family: Comfortaa;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.02em;
    color: #0A2452;
  }
}

.fileUploadInput {
  background-color: transparent;
  text-align: left;
  height: 37px;
  background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0)'%3E%3Cpath d='M2.04782 13.3853L10.9522 4.48087C11.961 3.47208 13.5966 3.47208 14.6054 4.48087C15.6142 5.48967 15.6142 7.12528 14.6054 8.13408L6.84246 15.8971L6.38588 15.4402L14.1489 7.67751C14.9055 6.92085 14.9055 5.6941 14.1489 4.93744C13.3922 4.18079 12.1655 4.18079 11.4088 4.93744L2.50439 13.8418C1.24334 15.1029 1.24334 17.1475 2.50439 18.4085C3.76545 19.6696 5.81001 19.6696 7.07106 18.4085L17.1169 8.36269C18.8823 6.59723 18.8823 3.73487 17.1169 1.96942C15.3514 0.203962 12.4891 0.203962 10.7236 1.96942L2.96096 9.7324L2.50439 9.27583L10.2674 1.51285C12.2854 -0.504666 15.5569 -0.504222 17.5744 1.51382C19.5919 3.53185 19.5915 6.80336 17.5735 8.82087L7.52763 18.8651C6.01444 20.3783 3.56102 20.3783 2.04782 18.8651C0.534629 17.3519 0.534629 14.8985 2.04782 13.3853Z' fill='%2325446B'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0'%3E%3Crect width='20' height='20' fill='white' transform='matrix(-1 0 0 1 20 0)'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A");
  background-position: right;
  background-repeat: no-repeat;

  &.white{
    background-color: white;
  }
}


select {
  font-family: Comfortaa;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.02em;
  border: 0px;
  border-bottom: 1px solid #0A2452;
  height: 36px;
  width: 100%;
  -webkit-appearance: none;
  -moz-appearance: none;
  cursor: pointer;

  background-image: url("data:image/svg+xml,%3Csvg width='8' height='7' viewBox='0 0 8 7' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7.06574 0.574707H0.934259C0.534911 0.574707 0.296715 1.01978 0.518233 1.35206L3.58397 5.95067C3.78189 6.24754 4.21811 6.24754 4.41603 5.95067L7.48177 1.35206C7.70328 1.01978 7.46509 0.574707 7.06574 0.574707Z' fill='%23263E60'/%3E%3C/svg%3E%0A");
  background-repeat: no-repeat;
  background-position: right;
  background-color: white;

  &.white{
    background-color: white;
  }


  &::placeholder {
    color: #0A2452;
    font-family: Comfortaa;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.02em;
    color: #0A2452;
  }
}
